@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .p-100 {
    padding-bottom: 100%;
  }

  .bg-black-50 {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
